<template>
  <div class="lottery-trend-dragon-tiger">
    <div class="header">
      <div class="title-box">
        <p class="title">龙虎走势</p>
        <intro-default cont="该走势图显示出每个位置上开出龙虎的趋势。"></intro-default>
      </div>
      <div class="search-box">
        <select-user type="1" @change="page_change"></select-user>
      </div>
    </div>
    <div class="lz_cont">
      <trendTable :list="groupData" :config="config"></trendTable>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Loading, Select, Option, Checkbox } from 'element-ui'
import trendTable from '@components/pc/trend-table'

export default {
  name: 'LotteryTrendDragonTiger',
  props: {
    code: String
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    [trendTable.name]: trendTable
  },
  data() {
    return {
      per_page: 10,
      data: null,
      groupData: [],
      loading: null,
      ranks: ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名', '冠亚和', '三四和', '五六和', '七八和', '九十和'],
      options: [
        {
          label: '40期',
          value: 30
        },
        {
          label: '90期',
          value: 90
        },
        {
          label: '今日所有',
          value: null
        }
      ],
      page: null,
      grouplength: 5,
      config: {}
    }
  },
  computed: {
    hasHE() {
      if (this.data && this.data.length > 0) {
        return this.data[0].list[0][0].he !== undefined
      }
      return false
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.per_page = 10
      this.requestGetHistory()
    },
    page: function (val, oldVal) {
      this.requestGetHistory()
    }
  },
  mounted() {
    this.requestGetHistory()
  },
  methods: {
    page_change(value) {
      this.per_page = value
      this.requestGetHistory()
    },
    refresh() {
      this.requestGetHistory()
    },
    allSelectAction(select) {
      for (const obj of this.groupData) {
        obj.selected = select
      }
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      const arr = ['table-cell']
      if ((row.loong === '龙' && columnIndex === 0) ||
        (row.tiger === '虎' && columnIndex === 1) ||
        (row.he === '和' && columnIndex === 2)) {
        arr.push('active')
      }
      return arr.join(' ')
    },
    requestGetHistory() {
      // this.showTableLoading()
      this.$api.loongTigerTrend(this.code, this.per_page).then((res) => {
        if (this.code === 'dongganwuxing' || this.code === 'azxy5' || this.code === 'txffc' || this.code === 'jisussc' || this.code === 'sg5d') {
          this.config = [{ lable: '龙', key: 'loong' }, { lable: '虎', key: 'tiger' }, { lable: '和', key: 'he' }]
        } else {
          this.config = [{ lable: '龙', key: 'loong' }, { lable: '虎', key: 'tiger' }]
        }
        this.groupData = this.getGroupData(res.data)
        // this.hideTableLoading()
      }).catch((err) => {
        // this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading() {
      if (this.loading) return
      this.loading = Loading.service({
        // target: '#table',
        fullscreen: true,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading() {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    },
    getGroupData(data) {
      this.ranks = this.get_type_name(this.code)
      let arr = []
      var i = 0
      while (i * this.grouplength < data.length) {
        const start = i * this.grouplength
        const end = start + this.grouplength < data.length ? start + this.grouplength : data.length
        const list = data.slice(start, end).map(({ list }, index) => {
          const title = this.ranks[start + index]
          return {
            title,
            list: list[0]
          }
        })
        arr = arr.concat(list)
        i++
      }
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
@import "~@scss/pc/mixin";

.lottery-trend-dragon-tiger {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    >.search-box {
      display: flex;
      align-items: center;

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  >.info {
    margin-top: 25px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);

    span {
      font-weight: bold;
    }
  }

  .tool {
    margin-bottom: 14px;
    display: flex;
    align-items: flex-end;

    >.options {
      >.group {
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        >.checkbox {
          min-width: 70px;
          margin-right: 15px;
        }
      }
    }

    >.btn {
      margin-left: 24px;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 24px;
      border: 1px solid #E3E3E6;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #222;
      cursor: pointer;

      >i {
        margin-right: 6px;
        color: #979799;
      }
    }
  }

  .table-group {
    margin-left: -5px;
    margin-right: -5px;
    display: flex;
  }

  .single_class {
    display: block;
    width: 100%;
    height: 33px;
    line-height: 33px;
    background-color: #FEEBEB;
    color: #FA3E3E;
  }

  .double_class {
    display: block;
    width: 100%;
    height: 33px;
    line-height: 33px;
    background-color: #E6F6FE;
    color: #07A6F8;
  }
}

.lottery-trend-dragon-tiger>.table-group {
  margin-top: 20px;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}
</style>

<style>
.lottery-trend-dragon-tiger>.table-group>.table {
  width: 25%;
  padding-left: 5px;
  padding-right: 5px;
}

.lottery-trend-dragon-tiger>.table-group>.table .table-header-cell {
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  height: 40px;
}

.lottery-trend-dragon-tiger>.table-group>.table .table-header-cell>.cell {
  padding: 0;
}

.lottery-trend-dragon-tiger>.table-group>.table:nth-child(2n) .table-cell.active {
  background-color: #3D9762 !important;
}

.lottery-trend-dragon-tiger>.table-group>.table .table-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
  height: 33px;
}

.lottery-trend-dragon-tiger>.table-group>.table .table-cell .active {
  background-color: #75A430 !important;
  color: white !important;
}


.lottery-trend-dragon-tiger>.table-group>.table .table-cell>.cell {
  padding: 0;
}

.lottery-trend-dragon-tiger>.table-group>.table .table-row:hover>td,
.lottery-trend-dragon-tiger>.table-group>.table .table-row .current-row>td {
  background-color: rgba(255, 204, 170, 0.4);
}
</style>
